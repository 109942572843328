*{
    margin: 0px;
    padding: 0px;
}
a{
    text-decoration: none;
}
.nav{
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.nav-background{
    position: absolute;
    left: 0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(199deg, rgba(0, 0, 0, 0.00) 45.31%, rgba(0, 0, 0, 0.84) 67.37%);
}
.main-image{
    opacity: 90%;
    width: 100%;
    height: 100%;
}
.nav-bar{
    width: 100%;
    height: 100px;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50%;
}
.main-logo{
    width: 20.88px;
    height: 42.92px;
    z-index: 20;
}
.nav-links{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    list-style: none;
    z-index: 10;
}
.nav-link{
    color: #FFF;
    font-family: 'Cairo', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.377px;
}
.nav-btn{
    border-radius: 0px 18px 0px 0px;
    background: #FFAC12;
    width: 211px;
    height: 50px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

/* 1100px Change */
@media (max-width:1100px) {
    .nav{
        position: relative;
        width: 100%;
        height: 70vh;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
    .nav-bar{
        width: 90%;
        height: 100px;
        margin-top: 30px;
        gap: 60%;
    }
    .main-logo{
        width: 10.88px;
        height: 32.92px;
    }
    .nav-links{
        gap: 30px;
        z-index: 10;
    }
    .nav-link{
        font-size: 12px;
    }
    .nav-btn{
        width: 111px;
        height: 40px;
        gap: 10px;
    }
}
@media (max-width:900px){
    .nav{
        height: 40vh;
    }
    .main-image{
        width: 100%;
        height: 100%;
    }
}
.nav-bar-small {
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    width: 100%;
    height: 50px;
    gap: 80%;
}
.main-logo-small{
    width: 10px;
    height: 30px;
}
#nav-btn{
    color: #FFAC12;
    font-size: 20px;
}
/* !!!!!!! */



/* ------------ */
.features{
    background: #E4E6F1;
    width: 100%;
    height: 100vh;
    gap: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.features-image{
    width: 50%;
    height: fit-content;
    border-radius: 40px;
    flex-shrink: 0;
}
.features-content{
    height: 50%;
    gap: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.features-content-tag{
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 140% */
    letter-spacing: -0.503px;
    color: #000;
    font-family: 'Cairo', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 140% */
    letter-spacing: -0.503px;
}
.border-text{
    background:linear-gradient(270deg ,  #000000 27%, #FFAC12 67%);
    width: 80%;
    height: 3px;
    margin-bottom: 30px;
}
.features-content-title{
    color: #000;
    text-align: right;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -1.256px;
    margin-bottom: 150px;
}

@media (max-width:800px) {
    .features-content-title{
        font-size: 35px;
    } 
}
@media (max-width:600px) {
    .features{
        height: 50vh;
        gap: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .features-image{
        display: none;
    }
    .features-content{
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .features-content-title{
        font-size: 40px;
        margin-bottom: 50px;
    }
}
/* !!!!! */


/* ------------ */

.sale{
    background: #F5F5F5;
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
}
.sale-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 50px;
}
.sale-border{
    width: 169px;
    height: 4px;
    border-radius: 4px;
    background:linear-gradient(270deg ,  #000000 27%, #FFAC12 67%);
}
.sale-title{
    color: #000;
    font-family: 'Cairo', sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -1.256px;
}
.sale-text{
    width: 351px;
    color: #000;
    font-family: 'Cairo', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 140% */
    letter-spacing: -0.503px;
}
.sale-image{
    width: 704px;
    height: 657px;
    border-radius: 57px 0px 0px 0px;
    border: 0px solid #FFF;
    box-shadow: 0px 62px 54px 0px rgba(0, 0, 0, 0.30);
}
@media (max-width: 1150px ) {
    .sale{
        background: #F5F5F5;
        width: 100%;
        height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 50px;
    }
    .sale-image{
        width: 404px;
        height: 357px;
        border-radius: 0px 0px 0px 0px;
    }
    .sale-content{
        justify-content: center;
        align-items: center;
        gap: 25px;
    }
    .sale-border{
        width: 300px;
    }
    .sale-text{
        width: 351px;
        color: #000;
        font-family: 'Cairo', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 140% */
        letter-spacing: -0.503px;
        text-align: center;
    }
}
@media (max-width: 450px ) {
    .sale{

        height: 50vh;
        gap: 25px;
    }
    .sale-image{
        width: 254px;
        height: 207px;
    }
    .sale-content{
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
    .sale-border{
        width: 200px;
    }
    .sale-title{
        font-size: 35px;
    }
    .sale-text{
        width: 251px;
        font-size: 14px;
    }

}
/* Rights Start */
.rights{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background: linear-gradient(226deg, #271A00 0.45%, rgba(0, 0, 0, 0.98) 100.47%);
    gap: 50px;
}
.rights-border{
    margin-top: 200px;
    width: 169px;
    height: 4px;
    background:linear-gradient(270deg ,  #000000 27%, #FFAC12 67%);
    border-radius: 4px;
}
.rights-title{
    color: #FFF;
    text-align: center;
    font-family: 'Cairo', sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -1.256px;
}
.rights-text{
    color: #8D8D8D;
    text-align: center;
    font-family: 'Cairo', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 140% */
    letter-spacing: -0.503px;
}
.rights-text span{
    color: #FFF;
}
@media (max-width:460px) {
    .rights{
        height: 60vh;
        gap: 50px;
    }
    .rights-title{
        padding-inline: 10px;
        font-size: 30px;
    }
    .rights-text{
        padding-inline: 10px;
        font-size: 15px;
    }
    .rights-text span{
        color: #FFF;
    } 
}




/* Footer start */
footer{
    width: 100%;
    height: 50vh;
    background: linear-gradient(226deg, #271A00 0.45%, rgba(0, 0, 0, 0.98) 45.25%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 60px;
}
.footer-content{
    width: 80%;
    padding-left: 60px;
    padding-bottom: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #7c7c7c;
}
.footer-title{
    color: #FFF;
    font-family: 'Cairo', sans-serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -1.055px;
}
.footer-title span{
    color: #FFAC12;
}
.footer-links{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: flex-start;
    margin-left: 160px;
    gap: 30px;
}
.footer-logo{
    width: 20.88px;
    height: 42.92px;
}
.footer-link{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    font-size: 20px;
    color: #FFF;
}
@media (max-width: 500px) {
    .footer-content{
        padding-left: 30px;
        padding-bottom: 20px;
    }
    .footer-title{
        font-size: 30px;
    }
    .footer-title span{
        color: #FFAC12;
    }
    .footer-links{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: center;
        margin-left: 0px;
    }
    .footer-logo{
        width: 20.88px;
        height: 42.92px;
    }
    .footer-link{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        font-size: 20px;
        color: #FFF;
    } 
}

/* More Styles */
a:hover{
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    transform: scale(1.10);
    color: #FFAC12 !important;
}